@use "../../styles/sass/base/colors" as *;
@use "../../styles/sass/base/typography" as *;
@use "../../styles/sass/layouts/flexbox" as *;
@use "../../styles/sass/abstracts/variables" as var;

.deliveries-section {
	@apply h-[98vh] px-[10%] py-[5%] flex--column flex-justify--center;

	@media (max-width: 1280px) {
		@apply px-[5%] pt-[6%];

		height: fit-content;
	}

	@media (max-width: 1200px) {
		height: fit-content;
		padding-top: 20%;
	}

	.page-title {
		@apply color--purplish-pure-500 font-title flex;
		align-self: center;
		align-items: center;
		gap: 1rem;
		margin-bottom: 5rem;

		@media (max-width: 1900px) {
			margin-bottom: 1rem;
		}

		svg {
			rotate: 90deg;
		}

		.title {
			@apply color--purplish-pure-500 font-title flex;
			font-weight: 800;
			font-size: var.$fonts-80;
			padding-bottom: 2rem;

			@media (max-width: 1900px) {
				font-size: var.$fonts-64;
			}

			@media (max-width: 700px) {
				font-size: var.$fonts-48;
				padding-bottom: 1rem;
			}
		}
	}
}

.swiper-wrapper {
	align-items: center;

	.swiper-slide {
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
		opacity: 0.5;
		transform: scale(0.9);
		justify-items: center;

		.delivery-card-container {
			.delivery-description,
			.delivery-title {
				visibility: hidden;
				place-self: center;
			}

			.delivery-logo {
				@apply background-color--yellowish-high-28 flex;

				border-radius: 100%;
				align-content: center;
				align-items: center;
				justify-content: center;
				justify-items: center;
				justify-self: center;
				width: 219px;
				height: 219px;

				svg {
					width: 60%;
					height: 60%;
				}
			}
		}

		&.swiper-slide-active {
			opacity: 1;
			transform: scale(1);
			z-index: 10;

			.delivery-card-container {
				display: grid;
				gap: 1rem;

				.delivery-description {
					@apply color--purplish-pure-500;
					display: block;
					visibility: visible;
					width: 30rem;
					height: 5rem;
					margin-bottom: 10vh;

					@media (max-width: 1900px) {
						margin-bottom: 8vh;
					}

					@media (max-width: 700px) {
						width: 15rem;
						height: 10rem;
						margin-bottom: 5rem;
					}
				}

				.delivery-title {
					@apply color--purplish-pure-500;
					display: block;
					font-weight: 800;
					visibility: visible;
				}

				.delivery-logo {
					@apply bg-yellowish-pure-500;

					border-radius: 100%;
					width: 20rem;
					height: 20rem;

					@media (max-width: 1900px) {
						width: 16rem;
						height: 16rem;
					}

					@media (max-width: 700px) {
						width: 15rem;
						height: 15rem;
					}
				}
			}
		}

		&.swiper-slide-prev {
			opacity: 1;
			transform: scale(1);
			z-index: 10;
		}

		&.swiper-slide-next {
			opacity: 1;
			transform: scale(1);
			z-index: 10;
		}
	}
}

.swiper-button-next,
.swiper-button-prev {
	color: var.$purplish-pure-500;
}

.swiper-pagination-bullet {
	background-color: var.$purplish-pure-500;
}
