@use "../abstracts/variables" as var;

.full-screen {
	height: 100vh;
	width: 100vw;
}

.height--full {
	height: 100%;
}

.width--full {
	width: 100%;
}

.width--fit-content {
	width: fit-content;
}

.container--full {
	height: 100%;
	width: 100%;
}

.margin-header {
	height: var.$header-height;
}

.wrapper-general {
	width: 100%;
	margin: 0 auto;
	padding: var.$spaces-80 8.5rem;
	@apply border-0 border-t border-solid border-grayish-pure-50;

	@media only screen and (max-width: 576px) {
		margin-bottom: var.$spaces-40;
		@apply px-6 py-10;

		&.full-white {
			background-color: var.$neutral-pure-50;
			padding: 0 var.$spaces-40 !important;
		}
	}

	&.padding-default {
		padding-top: var.$spaces-24;
	}

	&.no-padding-top {
		padding-top: 0;
	}

	&.no-padding {
		padding: 0;
	}
}

%stack-shared {
	display: flex;
	flex-direction: column;
}

.stack {
	@each $suffix, $space in var.$spaces {
		&--#{$suffix} {
			@extend %stack-shared;

			> *:not(:last-child) {
				margin-bottom: $space;
			}
		}
	}
}

.border-stack {
	@each $suffix, $space in var.$spaces {
		&--#{$suffix} {
			@extend %stack-shared;

			> *:not(:last-child) {
				padding-bottom: calc($space / 2);
				border-bottom: 1px var.$grayish-pure-50 solid;
				margin-bottom: calc($space / 2);
			}
		}
	}
}

%inline-shared {
	display: flex;
	flex-direction: row;
}

.inline {
	@each $suffix, $space in var.$spaces {
		&--#{$suffix} {
			@extend %inline-shared;

			> *:not(:last-child) {
				margin-right: $space;
			}
		}
	}
}

.row > div {
	margin-bottom: var.$spaces-16;

	@media only screen and (max-width: 576px) {
		margin-bottom: var.$spaces-40;
	}
}

.desktop-show {
	display: block;
}

.desktop-hide {
	display: none;
}

@media only screen and (max-width: 770px) {
	.desktop-show {
		display: none;
	}

	.desktop-hide {
		display: block;
	}
}
