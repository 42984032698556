@use "../../styles/sass/base/colors" as *;

.welcome-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 95vh;
	padding-left: 8rem;
	line-height: 13rem;

	@media (max-width: 768px) {
		padding-left: 2rem;
		line-height: 5rem;
	}
}

.welcome-section h1 {
	font-size: 14em;
	margin: 0;

	@media (max-width: 1165px) {
		font-size: 10em;
		line-height: 1;
	}

	@media (max-width: 768px) {
		font-size: 5rem;
	}
}

.welcome-section p {
	@apply color--neutral-pure-50;
	align-self: start;
	margin-top: 10px;
	float: inline-start;
	font-size: 1.5rem;
	line-height: 5rem;

	@media (max-width: 768px) {
		font-size: 1.2rem;
		line-height: 2.5rem;
	}
}

.welcome-section .first-line {
	align-items: baseline;
	display: flex;
	gap: 1rem;

	@media (max-width: 768px) {
		gap: 1rem;
	}
}

.welcome-section .brand-icon {
	transform: rotate(29deg);

	@media (max-width: 768px) {
		max-width: 3rem;
		max-height: 3rem;
	}
}
