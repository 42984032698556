/*** SPACING ***/
$spaces-0: 0; //0px
$spaces-4: 0.25rem; //4px
$spaces-8: 0.5rem; //8px
$spaces-10: 0.625rem; //10px
$spaces-12: 0.75rem; //12px
$spaces-16: 1rem; //16px
$spaces-24: 1.5rem; //24px
$spaces-28: 1.75rem; //28px
$spaces-32: 2rem; //32px
$spaces-36: 2.25rem; //36px
$spaces-40: 2.5rem; //40px
$spaces-48: 3rem; //48px
$spaces-56: 3.5rem; //56px
$spaces-64: 4rem; //64px
$spaces-80: 5rem; //80px

$spaces: (
	0: $spaces-0,
	1: $spaces-4,
	2: $spaces-8,
	3: $spaces-16,
	4: $spaces-24,
	5: $spaces-28,
	6: $spaces-32,
	7: $spaces-36,
	8: $spaces-40,
	9: $spaces-48,
	10: $spaces-56,
	11: $spaces-64,
	12: $spaces-80
);

/*** FONTS ***/
$fonts-10: 0.625rem; // 10px
$fonts-12: 0.75rem; // 12px
$fonts-14: 0.875rem; // 14px
$fonts-16: 1rem; // 16px
$fonts-18: 1.125rem; // 18px
$fonts-20: 1.25rem; // 20px
$fonts-24: 1.5rem; // 24px
$fonts-28: 1.75rem; // 28px
$fonts-32: 2rem; // 32px
$fonts-36: 2.25rem; // 36px
$fonts-40: 2.5rem; // 40px
$fonts-48: 3rem; // 48px
$fonts-64: 4rem; // 64px
$fonts-80: 5rem; // 80px
$fonts-96: 6rem; // 96px
$fonts-170: 10.625rem; // 170px
$fonts-200: 12.5rem; // 200px

$fonts-body: (
	tiny: $fonts-10,
	extra-small: $fonts-12,
	small: $fonts-14,
	medium: $fonts-16,
	large: $fonts-18
);

$fonts-heading: (
	tiny: $fonts-20,
	extra-small: $fonts-24,
	small: $fonts-28,
	medium: $fonts-32,
	large: $fonts-36,
	extra-large: $fonts-40
);

$fonts-display: (
	tiny: $fonts-48,
	small: $fonts-64,
	medium: $fonts-80,
	large: $fonts-96
);

$line-small: 125%;
$line-regular: 150%;

$line: (
	small: $line-small,
	regular: $line-regular
);

$weight-light: 300;
$weight-regular: 400;
$weight-semibold: 600;
$weight-bold: 700;

$font_weights: (
	light: $weight-light,
	regular: $weight-regular,
	semibold: $weight-semibold,
	bold: $weight-bold
);

$letter-spacing-lg: 0.1rem;

/*** COLORS ***/
$neutral-pure-50: #ffffff;
$neutral-pure-500: #000000;
$yellowish-low-950: #c6d095;
$yellowish-pure-500: #d8ea4a;
$yellowish-high-50: #e4f082;
$yellowish-high-28: rgba(216, 234, 74, 0.28);
$purplish-low-950: #18186b;
$purplish-pure-500: #504fe1;
$purplish-high-50: #8c8cb4;
$grayish-pure-50: #d9d9d9;
$grayish-low-500: #ececec;
$grayish-high-50: #dcdcf9;
$grayish-high-100: #f5f5f5;
$transparent: transparent;

$colors: (
	neutral: (
		"pure-50": $neutral-pure-50,
		"pure-500": $neutral-pure-500,
		"transparent": $transparent
	),
	yellowish: (
		"low-950": $yellowish-low-950,
		"pure-500": $yellowish-pure-500,
		"high-50": $yellowish-high-50,
		"high-28": $yellowish-high-28
	),
	purplish: (
		"low-950": $purplish-low-950,
		"pure-500": $purplish-pure-500,
		"high-50": $purplish-high-50
	),
	grayish: (
		"pure-50": $grayish-pure-50,
		"low-500": $grayish-low-500,
		"high-50": $grayish-high-50,
		"high-100": $grayish-high-100
	)
);

/*** DIRECTIONS ***/
$directions: (
	l: "left",
	r: "right",
	t: "top",
	b: "bottom"
);

/*** LAYOUTS ***/
$header-height: 4.5rem;
$heading-height: 3.5rem;
