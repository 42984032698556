@use "../../styles/sass/abstracts/variables" as var;

.hamburger-menu {
	background: var.$transparent;
	border: none;
	cursor: pointer;

	.bar {
		display: flex;
		width: 25px;
		height: 3px;
		margin: 5px 0;
		transition: 0.4s;
	}
}
