@use "../../styles/sass/abstracts/variables" as var;

.mobile-menu {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	background-color: var.$purplish-low-950;
	text-align: center;
	padding: 12rem 0;
	transform: translateX(-100%);
	transition: transform 0.5s ease;
	z-index: 20;
	justify-content: space-between;
	flex-flow: column;
	display: flex;

	.close-button {
		align-self: flex-end;
		margin-right: 2rem;
		margin-top: -9rem;
	}

	.links-wrapper {
		list-style: none;
		padding: 0;
		align-self: center;

		li {
			margin: 2rem 0;
		}
	}
}

.open {
	transform: translateX(0);
}
