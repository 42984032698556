.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-justify--between {
	display: flex;
	justify-content: space-between;
}

.flex-justify--start {
	display: flex;
	justify-content: flex-start;
}

.flex-justify--end {
	display: flex;
	justify-content: flex-end;
}

.flex-justify--center {
	display: flex;
	justify-content: center;
}

.flex-items--center {
	display: flex;
	align-items: center;
}

.flex-items--start {
	display: flex;
	align-items: flex-start;
}

.flex-items--end {
	display: flex;
	align-items: flex-end;
}

.flex-items--stretch {
	display: flex;
	align-items: stretch;
}

.flex--column {
	display: flex;
	flex-direction: column;
}

.flex--column-reverse {
	display: flex;
	flex-direction: column-reverse;
}

.flex--row {
	display: flex;
	flex-direction: row;
}

.flex--row-reverse {
	display: flex;
	flex-direction: row-reverse;
}

.flex--1 {
	flex: 1;
}

.flex--2 {
	flex: 2;
}

.flex--3 {
	flex: 3;
}

.flex--4 {
	flex: 4;
}

.flex--5 {
	flex: 5;
}

.flex--6 {
	flex: 6;
}

.flex--7 {
	flex: 7;
}

.flex--8 {
	flex: 8;
}

.flex--9 {
	flex: 9;
}

.flex-children--1 {
	display: flex;

	> * {
		flex: 1;
	}
}
