.main-page {
	text-align: center;

	.welcome-section {
		@apply bg-purplish-pure-500;
	}

	.about-section {
		@apply bg-neutral-pure-50;
	}

	.contact-section {
		@apply bg-yellowish-high-50;
	}
}
