@use "../../styles/sass/base/colors" as *;
@use "../../styles/sass/base/typography" as *;
@use "../../styles/sass/layouts/flexbox" as *;
@use "../../styles/sass/abstracts/variables" as var;

.contact-section {
	@apply h-[100vh] px-[10%] pt-[10%] flex--column flex-justify--center;

	line-height: 12rem;
	padding-top: 22%;
	padding-bottom: 30%;

	@media (max-width: 1200px) {
		height: fit-content;
		padding-left: 2rem;
		padding-top: 30%;
		line-height: 10rem;
		margin-bottom: 0%;
		padding-bottom: 15rem;
	}

	@media (max-width: 700px) {
		height: fit-content;
		padding-left: 2rem;
		padding-top: 0%;
		line-height: 5rem;
		margin-bottom: 0%;
		padding-bottom: 30rem;
	}
}

.contact-section .first-line {
	@apply font-title;

	align-items: baseline;
	color: var.$purplish-pure-500;
	display: flex;
	font-size: var.$fonts-200;
	gap: 1rem;

	@media (max-width: 1299px) {
		font-size: var.$fonts-170;
	}

	@media (max-width: 700px) {
		font-size: var.$fonts-64;
	}
}

.contact-section .bold {
	font-weight: 700;
}

.contact-wrapper .call-to-action {
	margin-top: 12rem;

	@media (max-width: 700px) {
		margin-top: 10rem;
	}
}

.contact-wrapper .contact-options-section {
	margin-top: 6%;
	margin-left: -4rem;
	gap: 4rem;
	display: flex;

	@media (max-width: 700px) {
		margin-top: 4rem;
		margin-left: 0rem;
	}
}

.contact-options {
	align-content: center;

	@media (max-width: 700px) {
		justify-self: center;
		display: grid;
		gap: 2rem;
	}
}

.contact-option {
	display: flex;
	align-items: center;
	height: 3rem !important;
	gap: 2rem;
	font-family: "Inter", Arial;
	font-size: var.$fonts-24;
	font-weight: 600;

	.text {
		@media (max-width: 700px) {
			display: none;
		}
	}
}
