@use "../../styles/sass/abstracts/variables" as var;

.language-switch {
	align-content: center;
	color: var.$neutral-pure-50;

	button {
		background: none;
		border: var.$neutral-pure-50;
	}
}
