@use "../../styles/sass/abstracts/variables" as var;

.link {
	text-align: center;
	width: 10rem;
	align-content: center;

	a {
		text-align: center;
		text-decoration: none;
		font-size: 1.4rem;
		display: inline-block;
	}

	@media (max-width: 1165px) {
		a {
			display: inline-block;
			width: 7rem !important;
			font-size: 1.1rem;
		}
	}

	@media (max-width: 768px) {
		margin: 2rem 0;
	}
}

.active {
	border-radius: 57px;

	.purple-active,
	.white-active {
		background-color: var.$yellowish-high-50;
		border-radius: inherit;

		a {
			color: var.$neutral-pure-500 !important;
		}
	}

	.yellow-active {
		background-color: var.$purplish-pure-500;
		border-radius: inherit;

		a {
			color: var.$yellowish-high-50 !important;
		}
	}
}
